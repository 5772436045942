import formImage from '@/assets/img/image1.jpg'
import { useRef } from 'react'
// import { Toast } from "@/components/toast";
import Loading from '@/components/loading'
import {emailSend} from './api'
import { useState } from 'react'
import { toast } from 'react-toastify';

const Toast  =  (message, type="warning") => {
     toast[type](message, {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

function Subscrible(props) {
    const { isShowTrack } = props
    const [canClick, setCanClick] = useState(true)

    const formDom = useRef()
    return (
        <div className="subscribe">
            {/* <h3>CONTACT US</h3>
            <p>&nbsp;</p> */}
            <div className="container">
                {isShowTrack ? <h4 className='subscribe-title'>Can’t find your parcel above or want to ask a question?</h4> : null } 
                <div className="sub-content">
                    <div className='embed-responsive-wrap'>
                        <div className="embed-responsive embed-responsive-16by9">
                            <img src={formImage} alt="" />
                        </div>
                        <div className='embed-responsive-mask'>LODGE AN ENQUIRY</div>
                    </div>
                    <div >
                        <div className="sub-message">
                            <form ref={formDom}>
                                <div className="input-group">
                                    <div className="kv-item">
                                        <label className="iconfont icon-asterisk"></label>
                                        <input type="text" id="first" name="first" placeholder='Name'/>
                                    </div>

                                    <div className="kv-item">
                                        <label className="iconfont icon-asterisk"></label>
                                        <input type="text" id="no" name="no" placeholder='Tracking No'/>
                                    </div>
                                    <div className="kv-item">
                                        <label className="iconfont icon-asterisk"></label>
                                        <input type="text" id="ship" name="ship" placeholder='Shipping Address'/>
                                    </div>
                                    <div className="kv-item">
                                        <label className="iconfont icon-asterisk"></label>
                                        <input type="text" id="post" name="post" placeholder='Postcode'/>
                                    </div>
                                    <div className="kv-item">
                                        <label className="iconfont icon-asterisk"></label>
                                        <input type="text" id="phone" name="phone" placeholder='Phone No'/>
                                    </div>

                                    <div className="kv-item">
                                        <label className="iconfont icon-asterisk"></label>
                                        <input type="text" id="email" name="email" placeholder='Email'/>
                                    </div>
                                </div>
                                <div className="kv-item form-textarea">
                                    <label className="iconfont icon-asterisk"></label>
                                    <textarea id="des" name="des" rows="2" placeholder='Message'></textarea>
                                </div>
                                <div className="kv-item form-submit">
                                    <button type='submit' onClick={async (e) => {
                                        e.preventDefault()

                                        if (!canClick) {
                                            return 
                                        }
                                        setCanClick(false)
                                        
                                        if (!formDom.current[0].value) {
                                            formDom.current[0].focus()
                                            setCanClick(true)
                                            return Toast('Name is required')
                                        } else if (!formDom.current[1].value) {
                                            formDom.current[1].focus()
                                            setCanClick(true)
                                            return Toast('Tracking No is required')
                                        } else if (!formDom.current[2].value) {
                                            formDom.current[2].focus()
                                            setCanClick(true)
                                            return Toast('Shipping Address is required')
                                        } else if (!formDom.current[3].value) {
                                            formDom.current[3].focus()
                                            setCanClick(true)
                                            return Toast('Postcode is required')
                                        }else if (!formDom.current[4].value && !Number(formDom.current[4].value)) {
                                            formDom.current[4].focus()
                                            setCanClick(true)
                                            return Toast('Phone No is required')
                                        }else if (!formDom.current[5].value) {
                                            formDom.current[5].focus()
                                            setCanClick(true)
                                            return Toast('Email is required')
                                        }else if (!formDom.current[6].value) {
                                            formDom.current[6].focus()
                                            setCanClick(true)
                                            return Toast('Message is required')
                                        }

                                        Loading.show()
                                        const Name = formDom.current[0].value || '';
                                        const TrackingNo = formDom.current[1].value || '';
                                        const ShippingAddress = formDom.current[2].value || '';
                                        const PosetCode = formDom.current[3].value || '';
                                        const PhoneNumber = formDom.current[4].value || '';
                                        const Email = formDom.current[5].value || '';
                                        const Message = formDom.current[6].value || '';

                                        const Subject = `${Name} LODGE AN ENQUIRY ${TrackingNo}`
                                        const data = {
                                            isHtml:false,
                                            enableDefaultCc:false,
                                            body: `
                                                   Name: ${Name},

                                                   TrackingNo: ${ TrackingNo}

                                                   PhoneNumber: ${ PhoneNumber}

                                                   PosetCode: ${ PosetCode}

                                                   Email: ${ Email}

                                                   ShippingAddress: ${ShippingAddress}

                                                   Message: ${Message}

                                            `
                                        }
                                        const My_EMAIL = 'support@thunderexpress.com.au'
                                        e.preventDefault()
                                        
                                        const res = await emailSend({to: My_EMAIL, subject: Subject, data}).catch(()=>{
                                            Loading.hide()
                                        })


                                        if (res.status === 200) {
                                            Toast(res?.data?.Message, 'success')
                                        }
                                        
                                        Loading.hide()
                                        setCanClick(true)
                                       
                                    }}>SUBMIT PARCEL ENQUIRY</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Subscrible