import nothingImg from '@/assets/img/express.png'

function Nothing () {
    return (
        <div className="nothingWrap">
            <img src={nothingImg} alt="" />
            <p>Nothing here</p>
        </div>
    )
}

export default Nothing