import React, { useState, useEffect } from 'react'

import Subscrible from './subscribe'
import Nothing from './nothing'
import RowText from './rowText.jsx'

import './index.scss'

import logoPng from '../../assets/img/logo.png'
import Loading from '@/components/loading'
import { shippmentTrackingApi } from './api'

const {
    NEXT_PUBLIC_VER_CODE,
    NEXT_PUBLIC_MAIN_VER
} = process.env

console.log(`%c测试订单-%cTE9968502735`, 'color: green; font-size: 30px', 'color: pink; font-size: 25px')

function Track() {

    // const [showRowText, setShowRowText] = useState(true)
    const [showClear, setShowClear] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [isShowAll, setIsShowAll] = useState(false)
    const [trackData, setTrackData] = useState([])
    const [isShowTrack, setIsShowTrack] = useState(false)
    const [showSearchOrder, setShowSearchOrder] = useState('')
    const [lastTrackText, setLastTrackText] = useState('')

    const handleSearchClick = async () => {
        if (!inputValue) {
            return false
        }
        await getData()
    }

    const convertUTCToLocalTime = (utcDateString) => {
        var date = new Date(utcDateString + 'Z');
        return date.toString();
    }

    const handleInputChange = (e) => {
        // console.log(e.target.value)
        if (!e.target.value) {
            return clearInputClick()
        }
        setInputValue(e?.target?.value)
        setShowClear(true)
    }

    const handleKeyUp = (e) => {
        // console.log(e.keyCode)
        if (e.keyCode === 13) {
            handleSearchClick()
        }
    }

    const clearInputClick = () => {
        setInputValue('')
        setShowClear(false)
        setIsShowTrack(false)
        setTrackData([])
    }

    const getData = async (trackingNumber) => {
        Loading.show();
        setTrackData([]);  // 确保清空现有数据
        const res = await shippmentTrackingApi({ TrackingNumber: trackingNumber || inputValue.trim() });
        setIsShowTrack(true);
        console.log('res', res);
        if (res && res.status === 200 && res.data && res.data.Data && res.data.Data.data) {
            let list = res.data.Data.data.trackResultList || [];
            if (list.length > 0) {
                // 创建一个新的数组引用
                list = list.sort((prev, next) => new Date(next.localTime || next.checkpointTime) - new Date(prev.localTime || prev.checkpointTime)).map(item => ({ ...item }));
                console.log('sorted list', list);
                const lastObj = list[0] || {};
                setLastTrackText(lastObj.statusString);
                setTrackData(list);  // 更新状态
            } else {
                setTrackData([]);  // 明确设置为空数组
                setLastTrackText('');
            }
        } else {
            setTrackData([]);
            setLastTrackText('');
        }
        setIsShowTrack(true);
        if (inputValue) {
            setShowSearchOrder(inputValue);
        }
        Loading.hide();
    }
    

    const init = () => {
        const trackingNumber = window.location.search.replace(/\?t=/, '')
        console.log('trackingNumber', trackingNumber)
        if (trackingNumber) {
            setInputValue(trackingNumber)
            setShowSearchOrder(trackingNumber)
            getData(trackingNumber)
        }
    }
    
    useEffect(()=>{
        init()
         // eslint-disable-next-line
    }, [])

    return (
        <div className='home'>
            <div className='banner'>
                <div className='container'>
                    <div className="banner-content">
                        <a href='/'><img className="img-responsive" src={logoPng} alt="" /></a>
                        <p><strong>Welcome To Thunder Express</strong></p>
                        <div className="input-container">
                            <input type="text" id="trackno" name="trackno" value={inputValue}
                                onInput={handleInputChange} placeholder='Enter tracking number' maxLength={16}
                                onKeyUp={handleKeyUp}
                            />
                            <div className="btn" onClick={handleSearchClick}>Track & Trace</div>
                            <span className='clearInput iconfont icon-clear'
                                onClick={clearInputClick}
                                style={{ display: showClear ? 'inline-block' : 'none' }}>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="feature">
                <div className="container">
                    {isShowTrack ? null : <RowText />}

                    {isShowTrack ?
                        <div className='track'>
                            <div className='track-head'>
                                <div className='track-head-number'>
                                    <i className='iconfont icon-sousuowenjian'></i>
                                    <div className='track-inner-text'>
                                        <p>Tracking number</p>
                                        <h3>{showSearchOrder || '--'} </h3>
                                    </div>
                                </div>
                                <div className='track-head-delivered'>
                                    <i className='iconfont icon-wancheng'></i>
                                    <div className='track-inner-text'>
                                        <p>Your parcel has been</p>
                                        <h3>{lastTrackText || '--'}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='track-cont'>
                                {/* <div className='track-cont-ref'>Ref: N855547-2</div> */}
                                <div className='track-cont-inner'>
                                    <div className='track-cont-inner-list'>
                                        <div className='track-cont-inner-head'>
                                            <div>Status</div>
                                            <div>Location</div>
                                            <div>Date/time</div>
                                        </div>

                                        {
                                            trackData.map((ele, index) => {
                                                ele.time = new Date(convertUTCToLocalTime(ele.localTime || ele.checkpointTime)).toLocaleTimeString()
                                                ele.date = new Date(convertUTCToLocalTime(ele.localTime || ele.checkpointTime)).toLocaleDateString()
                                                return (
                                                    <div className={`track-cont-inner-item ${index === 0 ? '' : (isShowAll ? '' : 'hide')}`} key={ele.statusString}>
                                                        <div>
                                                            <p>{ele.statusString}</p>
                                                            <span>{ele.message}</span>
                                                        </div>
                                                        <div>
                                                            <p>{ele.location || ''}</p>
                                                            <span></span>
                                                        </div>
                                                        <div>
                                                            <p>{ele.date}</p>
                                                            <i>{ele.time}</i>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                    {
                                        trackData.length > 0 ?
                                            <div className='track-btn-wrap' style={{ display: trackData.length > 1 ? 'flex' : 'none' }}>
                                                <div className={`iconfont icon-${isShowAll ? 'xiangshangyuanjiantoushangjiantouxiangshangmianxing' : 'xiangxiayuanjiantou'}`} onClick={() => {
                                                    setIsShowAll(!isShowAll)
                                                }}></div>
                                                <p>Tracking history</p>
                                            </div>

                                            : <Nothing />
                                    }

                                </div>
                            </div>
                        </div> : null
                    }

                </div>
            </div>

            <Subscrible isShowTrack={isShowTrack} />

            <footer>
                <div className="container">
                    <p className="copy-right"> Copyright  2023 <a href="/">Thunder Express</a> Australia | All rights reserved.  <span>version:{NEXT_PUBLIC_MAIN_VER} - {NEXT_PUBLIC_VER_CODE}</span></p>
                </div>
            </footer>
        </div>
    )
}

export default Track