import axios from "axios";
import { toast } from 'react-toastify';

const instance = axios.create({
    // baseURL: process.env.REACT_APP_BASE_URL,
    // prefix: '/trackingapi',
    baseURL: '/trackingapi',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
});

// const idsstsInstance = axios.create({
//     baseURL: '/idsstsapi',
//     mode: 'cors',
// });

instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    config.headers['Authorization'] = sessionStorage.getItem('__account_token')
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  }, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log(error)
    const res = error.response

    if (res && res.data) {
      // Toast(res.data.Message)
      toast.error(res.data.Message, {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    return Promise.reject(error);
  });

//   let baseQuery = {
//     client_id: 'thunderexpressapi',
//     client_secret: 'secret',
//     grant_type: 'password',
//   }

//   let obj = process.env.REACT_APP_NODE_ENV === 'development' ? {
//     ...baseQuery,
//     username: 'aftership',
//     password: 'KkTq84G9h%Bn~203?6RYytMa7{dVN)r-'
//   } : {
//    ...baseQuery,
//     username: 'aftership',
//     password: '0G+vB83cXDIW5Om%hKP6rl7_1^|a2oq)'
// }

//   export const loginGetToken = async () => {
//     const storageExpiresDate = sessionStorage.getItem('__expires_in') || 0
//     const startTime = new Date().valueOf()
//     console.log(`token过期时间-${storageExpiresDate}`, `${parseInt((startTime - storageExpiresDate)/1000)}秒后过期`)
//     if ( startTime >= storageExpiresDate) {
//       sessionStorage.removeItem('__account_token')
//       sessionStorage.removeItem('__expires_in')
//     }

//     const token = sessionStorage.getItem('__account_token')

//     if (!token) {
//       const res = await idsstsInstance({
//         headers:{
//           'Content-Type':'application/x-www-form-urlencoded',
//         },
//         method: 'post',
//         url:  '/connect/token',
//         data:  Qs.stringify(obj),
//       });
//       if (res.status === 200 && res.data) {
//         const typeToken =  res.data.token_type + ' ' + res.data.access_token
//         sessionStorage.setItem('__account_token', typeToken)
//         let nowTime = new Date().valueOf()
//         let expiresDate = Number(nowTime) + (res.data.expires_in * 1000)

//         sessionStorage.setItem('__expires_in', expiresDate)

//         let time = 0
//         timer = setInterval(()=> {
//           time += 1
//           if (time >= (3600 - 1)) {
//             sessionStorage.removeItem('__account_token')
//             loginGetToken()
//             clearTimeout(timer)
//           }
//         } , 1000)
//       }
//       // sessionStorage.setItem('__account_res_info', JSON.stringify(res))
//       console.log(res)
//     }
//   }
  // loginGetToken()
  export default instance