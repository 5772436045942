import instance from "@/request";

// 获取列表
export async function shippmentTrackingApi(params={}) {
    return instance
      // .get('https://trackingapi.super10.com.au/v1/tracking/shippment/tracking', {
      // .get('/v1/tracking/shippment/tracking', {
      .get('/v1/tracking/Shippment/TrackingInternal', {
        params: {...params},
        prefix: '',
      })
      .then((res) => {
        return res;
      }).catch((err) => {
        return err
      });
  }

export async function emailSend({to='', subject='', data}) {
  return instance
    // .get('https://trackingapi.super10.com.au/v1/tracking/shippment/tracking', {
    .post(`/v1/tracking/Email/Send/${to}/${subject}`, {
      ...data,
    })
    .then((res) => {
      return res;
    }).catch((err) => {
      return err
    });;
}