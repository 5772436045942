import React from 'react';
import { createRoot } from 'react-dom/client';
// import ReactDOM from 'react-dom';
import './index.scss'
class Loading {
    constructor() {
        this.domNode = document.createElement('div') || {};
        this.isExistNode = false;
    }

    render(visible) {
        if (!this.isExistNode && visible) {
            document.body.appendChild(this.domNode);
            const children = this.createNode();
            // ReactDOM.render(children, this.domNode);
            createRoot(this.domNode).render(children)
            this.isExistNode = true
        }
        if (visible) {
            this.domNode.className = 'hp-loading-wrap';
        } else {
            this.domNode.className = 'hp-loading-wrap hide';
            // ReactDOM.unmountComponentAtNode(this.domNode)
        }
    } 
    createNode() {
        const node = <div className="loading-content"><div className="loading-img"></div></div>;
        return node;
    }

    show(isDelay=true, delay=300) {
        this.timer && clearTimeout(this.timer)
        if (!isDelay) {
            this.render(true);
        } else {
            // 防闪烁
            this.timer = setTimeout(() => this.render(true), delay);
        }
    }

    hide() {
        this.timer && clearTimeout(this.timer)
        this.render(false)
    }
}
const loadingObj = new Loading()
export default loadingObj